import type { NextPage } from 'next'
import { useEffect } from 'react'
import { captureException, withScope, Severity } from '@sentry/nextjs'

import { PageLayout } from 'src/libs/layout'
import {
    CONTENT_CONTAINER_STYLES,
    HEADING_1_STYLES,
    LinkButton,
    PARAGRAPH_INTRO_STYLES,
} from 'src/libs/design-system'
import { twMerge } from 'src/libs/tailwind-css'
import { HtmlHead } from 'src/libs/html-head'

const Error404Page: NextPage = () => {
    useEffect(() => {
        withScope((scope) => {
            scope.setLevel(Severity.Error)
            scope.setTag('referrer', document.referrer || 'none')
            captureException(new Error('Landed on 404 page'))
        })
    }, [])

    return (
        <PageLayout>
            <HtmlHead title="404" />
            <div
                className={twMerge(
                    CONTENT_CONTAINER_STYLES,
                    'pt-16 min-h-full grid content-center justify-items-center gap-12 text-center'
                )}
            >
                <div className="grid gap-6 w-full max-w-xl">
                    <h1 className={HEADING_1_STYLES}>Phew, this is a 404.</h1>
                    <p className={twMerge(PARAGRAPH_INTRO_STYLES, 'sm:w-[85%] mx-auto')}>
                        This is probably not the page you were looking for. You can go back or read
                        the{' '}
                        <a
                            href="/bitcoin.pdf"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                        >
                            Bitcoin White Paper
                        </a>
                        .
                    </p>
                </div>
                <LinkButton href="/" variant="primary" size="large" arrowRight>
                    Back to home
                </LinkButton>
            </div>
        </PageLayout>
    )
}

export default Error404Page
